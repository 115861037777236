.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.page-number {
  margin-top: 15px;
  color: white;
}
.break-me {
  color: white;
  margin-top: 15px;
}
.pagination.previous {
  margin-top: -15px !important;
}
.next {
  background: var(--base-dark, #33353b);
  color: white !important;
  border: none;
  padding: 10px 30px;
  margin-left: 10px;
}
.next {
  -webkit-clip-path: polygon(
    0px 0%,
    calc(100% - 0px) 0%,
    100% 0px,
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    18px 100%,
    0% calc(100% - 18px),
    0% 0px
  );
  clip-path: polygon(15% 0, 100% 0, 100% 75%, 86% 100%, 0 100%, 0 90%, 0 30%);
}
.previous {
  background: var(--base-dark, #33353b);
  color: white !important;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
}
.previous {
  -webkit-clip-path: polygon(
    50% 0%,
    85% 0,
    100% 30%,
    100% 100%,
    15% 100%,
    0 70%,
    0 0
  );
  clip-path: polygon(50% 0%, 85% 0, 100% 30%, 100% 100%, 15% 100%, 0 70%, 0 0);
}
.pagination > .active > a {
  background-color: var(--base-danger, red);
  color: #fff !important;
}
.pagination > li > a {
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: var(--base-danger, red);
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: var(--base-danger, red);
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
@media only screen and (max-width: 400px) {
  .pagination-center {
    font-size: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .page-info {
    position: absolute;
    top: 95%;
  }
}
