/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  overflow-y: scroll;
  font-family: "Montserrat", sans-serif !important;
  background-color: #1f3041;
  background-image: linear-gradient(147deg, #161a26 0%, #000000 90%);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Montserrat", sans-serif !important;
  color: white;
}

*:focus {
  outline: none !important;
}

button:focus {
  outline: 0 !important;
}

a {
  color: inherit !important;
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.table-dark {
  color: #fff;
  /* background-color: #212529 !important; */
  background-color: #090e1a !important;
}

.headroom.headroom--pinned.headroom--scrolled {
  z-index: 99999999999999999 !important;
}

.fullscreen-gif {
  width: 100%;
  height: 350;
  -webkit-filter: blur(10px); /* Safari 6.0 - 9.0 */
  filter: blur(10px);
  /* background-image: linear-gradient(to right, red, yellow),
    url{require("./LED.gif")}; */
}

#bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.card {
  border-color: var(--base-white-a10) !important;
}
