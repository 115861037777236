ol.breadcrumb {
  background: transparent !important;
}

*:focus {
  outline: none !important;
}

#savePercent {
  color: var(--base-warning, #22f4ee) !important;
}

#fixed-bottom {
  background-color: var(--base-danger, #e50926);
  border: none !important;
  opacity: 0.5;
}

#fixed-bottom-opac {
  background-color: var(--base-danger, #e50926);
  border: none !important;
}

button:focus {
  outline: none !important;
}

#originalPrice {
  color: #6c757d;
  text-decoration: line-through;
  padding-left: 10px;
}

#checkbox {
  margin-right: 10px;
}

.search-result-container {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.search-title {
  color: white;
}

/******CARDS CUT******/

#displayCard {
  background: transparent !important;
  color: white;
  border: none; /*remove for test*/
}

/* .modal-content {
    width: 100%;
} */
/*Media Query*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #stackBottom {
    width: 92.5%;
    height: 93.5%;
    right: 9px !important;
  }
  /*MODAL FULLSCREEN*/
  /* .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 0px;
    } */
  /* width: 100% !important; */
  /* .modal-content {
      
      height: 100%;
      margin: 0;
      border-radius: 0px;
    } */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #stackBottom {
    width: 88%;
    height: 92.5%;
    right: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #stackBottom {
    width: 88%;
    height: 92.5%;
    right: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #stackBottom {
    width: 88%;
    height: 92.5%;
    right: none;
  }
  .fixed-bottom {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #stackBottom {
    width: 91%;
    height: 95.5%;
    right: none;
  }
  .fixed-bottom {
    display: none;
  }
}
