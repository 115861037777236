// Background Colors

@each $i
  in (
    inverted,
    dark,
    darker,
    dark-light,
    warning,
    danger,
    0,
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100
  )
{
  .bg-base-#{$i} {
    background-color: var(--base-#{$i}) !important;
  }
  .text-base-#{$i} {
    color: var(--base-#{$i}) !important;
  }
}
