@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800&display=swap");

/* Font Family */
@each $i in (primary, heading) {
  .ff-#{$i} {
    font-family: var(--ff-#{$i});
  }
}

:root {
  --ff-body: "Montserrat", sans-serif;
  --ff-heading: "Open Sans", sans-serif;
}
