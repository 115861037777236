.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-radius: 0px;
  background: #cdcdcd;
  border: none;
}

capitalize {
  text-transform: capitalize;
}

nav.nav.nav-tabs {
  border: none !important;
  border-radius: 0px !important;
  justify-content: center;
}

.nav-tabs .nav-link:hover {
  border: none !important;
}

.nav-tabs .nav-link {
  border: none !important;
}

.nav-tabs .nav-link:focus {
  border: none !important;
}

.product-title {
  text-transform: capitalize;
}

a#uncontrolled-tab-example-tab-home {
  border-radius: 0px !important;
  padding: 14px 21px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: rgb(12, 14, 19) !important;
  font-weight: 800;
  /* This timing applies on the way OUT */
  transition-timing-function: ease-in;

  /* Quick on the way out */
  transition: 0.4s;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #cdcdcd !important;
  font-weight: 800;
}

a#uncontrolled-tab-example-tab-profile {
  border-radius: 0px !important;
  padding: 14px 21px;
}

/* .nav-tabs {
  -webkit-clip-path: polygon(
    10px 0%,
    calc(100% - 0px) 0%,
    100% 0px,
  100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0% calc(100% - 0px),
    0% 10px
  );
  clip-path: polygon(
    0px 0%,
    calc(100% - 18px) 0%,
    100% 18px,
 100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    18px 100%,
    0% calc(100% - 18px),
    0% 0px
  );
  border-color: transparent;
} */

a#uncontrolled-tab-example-tab-profile {
  -webkit-clip-path: polygon(
    10px 0%,
    calc(100% - 0px) 0%,
    100% 0px,
    /*right end*/ 100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0% calc(100% - 0px),
    0% 10px
  );
  clip-path: polygon(
    0px 0%,
    calc(100% - 18px) 0%,
    100% 18px,
    /*right end*/ 100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    0px 100%,
    0% calc(100% - 0px),
    0% 0px
  );
}

a#uncontrolled-tab-example-tab-home {
  -webkit-clip-path: polygon(
    0px 0%,
    calc(100% - 0px) 0%,
    100% 0px,
    /*right end*/ 100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    18px 100%,
    0% calc(100% - 18px),
    0% 0px
  );
  clip-path: polygon(
    0px 0%,
    calc(100% - 0px) 0%,
    100% 0px,
    /*right end*/ 100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    18px 100%,
    0% calc(100% - 18px),
    0% 0px
  );
}

.nav-tabs .nav-link {
  background: rgb(205, 205, 205, 0.2);
}

.loading {
  display: inline-block;
  margin-left: -5px;
  margin-right: 5px;
}

.product-wishlist-btn {
  padding: 8px 12px 8px 12px !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  background: rgb(34, 244, 238, 0.2) !important;
  color: #22f4ee !important;
  border: none !important;
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif !important;
}
.product-wishlist-btn-disabled {
  padding: 8px 12px 8px 12px !important;
  border-radius: 0px !important;
  color: black !important;
  background: #6a8584 !important;
}
.product-wishlist-btn-disabled:hover{
  cursor: no-drop;
}
.outofstock{
  padding: 8px 12px 8px 12px !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  background: rgb(100 16 16 / 87%) !important;
  color: #ffffff !important;
  border: none !important;
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif !important;
}
.outofstock:hover{
  cursor: no-drop;
}

.input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
  border-radius: 0px;

}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #productCarouselCol {
    padding-top: 0px;
    margin-top: -20px;
  }
  .MuiButton-root {
    font-size: 10px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #productCarouselCol {
    padding-top: 0px;
    margin-top: -30px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #productCarouselCol {
    padding-top: 0px;
    margin-top: -30px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #productCarouselCol {
    padding-top: 43px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #productCarouselCol {
    padding-top: 43px;
  }
}
