/* .modal-content{
  height: 100% !important;
  width: 100%!important;
} */
.react-multiple-carousel__arrow {
  background: var(--base-white-a10, #2931314f) !important;
  z-index: 10 !important;
}
#table-price {
  padding: 0;
  margin: 0;
  font-size: 12px;
  padding-top: 10px;
}
.case-image {
  text-align: center;
}
.case-column-pricing {
  display: flex;
  justify-content: space-between;
  padding: 25px 30px 20px;
  align-items: center;
  border-bottom: 1px solid;
  border-top: 1px solid;
  /* width: 85%; */
  margin-left: 25px;
}
.case-column-miscellanous {
  display: flex;
  justify-content: space-between;
  padding: 25px 30px 20px;
  border-top: 1px solid;
  align-items: center;
  border-bottom: 1px solid;
  /* width: 85%; */
  margin-left: 25px;
  margin-bottom: 35px;
}
.preview-btn {
  width: 30%;
  padding: 8px 12px 8px 12px !important;
  /* margin-left: 18px !important; */
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  background: var(--base-danger, #e60023) !important;
  border: none !important;
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif !important;
}

.preview-btn:hover {
  box-shadow: 0 0 2px rgba(236, 36, 52, 0.8), 0 0 8px rgba(236, 36, 52, 0.8),
    0 0 8px rgba(236, 36, 52, 0.8);
}

.save-btn,
.save-btn-disabled {
  padding: var(--su-3) var(--su-5);
  border-radius: var(--radius-0);
  background: var(--base-success-a10, rgba(34, 244, 237, 0.068)) !important;
  color: var(--base-success, #22f4ee) !important;
  border: none !important;
  cursor: no-drop !important;
  font-size: var(--fs-sm);
  font-family: var(--ff-body);
  text-transform: uppercase;
}
.save-btn-disabled {
  opacity: 0.8;
}

.save-btn:hover {
  box-shadow: 0 0 2px var(--base-success-a10), 0 0 8px var(--base-success-a10),
    0 0 8px var(--base-success-a10);
  color: var(--base-inverted, black) !important;
  background: var(--base-success, #22f4ee) !important;
}

.save-btn-disabled:hover {
  background: var(--base-success-a10, rgba(34, 244, 237, 0.068)) !important;
  cursor: no-drop;
}

.container-carousel {
  padding: 20px;
  /* background: #20282d; */
  height: 415px;
  /* width: 90%; */
}
.carousel-headings {
  display: flex;
  justify-content: space-around;
}
.icon-div {
  cursor: pointer;
  color: white;
  font-weight: 800;
  padding: 10px 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.icon-div-active {
  transition: 0.1s;
  text-align: center;
  padding: 10px 25px;
  background: #75757521;
  font-weight: 800;
  color: white;
  border-bottom: 3px solid var(--base-danger, red);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
textarea:focus,
input:focus {
  border: none;
}
.icon-div:hover {
  transition: 0.1s;
  font-weight: 800;
  text-align: center;
  padding: 10px 25px;
  background: #75757521;
  color: white;
  border-bottom: 3px solid var(--base-danger, red);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.icon-div-no-hover {
  cursor: pointer;
  color: white;
  font-weight: 800;
  padding: 10px 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.icon-div-no-hover:hover {
  cursor: no-drop;
}
.tiny-icons {
  color: gray;
  margin-right: 5px;
}
.tiny-icons:hover {
  color: var(--base-danger, #ff0202);
}
.outer-div {
  text-align: center;
}
.icon-heading {
  margin-top: 10px;
  font-size: 11px;
  color: white;
  height: 50px;
}
.BrainhubCarousel {
  overflow: hidden;
  display: flex;
  align-items: center;
  /* width: 90%; */
}
.heading {
  width: 100% !important;
}
.container-icon-left {
  color: var(--base-danger, red);
  font-size: 30px;
  cursor: pointer;
  z-index: 10;
}
.container-icon-right {
  color: var(--base-danger, red);
  font-size: 30px;
  cursor: pointer;
  z-index: 10;
}
.icon-left {
  color: gray;
  font-size: 25px;
  cursor: pointer;
}
.icon-right {
  color: gray;
  font-size: 25px;
  cursor: pointer;
}
.platform {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.select {
  font-size: 12px;
  border: 1px solid;
  padding: 5px 0px;
  cursor: pointer;
  width: 95px;
  text-align: center;
}
.selected {
  font-size: 12px;
  background-color: var(--base-danger, #e60023);
  border: 1px solid var(--base-danger, #e60023);
  padding: 5px 0px;
  cursor: pointer;
  width: 95px;
  text-align: center;
}
.selected:hover {
  background-color: transparent;
}
.category {
  color: white !important;
  cursor: pointer;
  text-transform: uppercase;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.category-active {
  color: var(--base-danger, red) !important;
  cursor: pointer;
  text-transform: uppercase;
  transition: 1s;
}
.category:hover {
  color: var(--base-danger, red) !important;
}
/* .category:hover {
  padding: 3px 7px;
  border: 1px solid #e60023;
  cursor: pointer;
} */
.product-name {
  /* margin-left: -245px; */
  position: absolute;
  /* left: 25%; */
  margin-left: 110px;
  width: 25%;
}

.sub-spec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 0px;
}
#info {
  margin-right: 10px;
  padding: 0px 6px;
  border: 1px solid;
  font-size: 12px;
}
.more-info {
  cursor: pointer;
  margin-left: 10px;
  font-size: 10px;
  color: var(--base-danger, red);
}
.case-col {
  border-left: 1px solid var(--base-white-a20, #414040);
}
.scroll-div {
  overflow-y: scroll;
  height: 300px;
}
.scroll-div-storage {
  overflow-y: scroll;
  height: 250px;
  margin-top: 10px;
}
.scroll-div-storage::-webkit-scrollbar {
  width: 2px;
  background-color: var(--base-darker, #212529);
}
.scroll-div-storage::-webkit-scrollbar-thumb {
  background-color: var(--base-danger, red);
  outline: 1px solid var(--base-danger, red);
}

.scroll-div::-webkit-scrollbar {
  width: 2px;
  background-color: #212529;
}
.scroll-div::-webkit-scrollbar-thumb {
  background-color: var(--base-danger, red);
  outline: 1px solid var(--base-danger, red);
}
.table-scroll {
  overflow-y: scroll;
  height: 350px;
}
.table-scroll::-webkit-scrollbar {
  width: 2px;
  background-color: #212529;
}
.table-scroll::-webkit-scrollbar-thumb {
  background-color: var(--base-danger, red);
  outline: 1px solid var(--base-danger, red);
}
.intelLogo {
  text-align: center;
}
.amdLogo {
  text-align: center;
}

.react-multi-carousel-list {
  min-height: 50px;
}
.skip {
  color: var(--base-danger, red);
  border: 1px solid;
  padding: 1px 12px;
}
.skip:hover {
  cursor: pointer;
}
#customize-stackbottom {
  display: block;
  text-align: center;
  height: 350px;
  padding: 15px;
  background-color: var(--base-white-a5, rgba(22, 26, 38, 0.5));
  color: white;
  -webkit-clip-path: polygon(
    40px 0%,
    calc(100% - 0px) 0%,
    100% 0px,
    /*right end*/ 100% calc(100% - 40px),
    calc(100% - 40px) 100%,
    10px 100%,
    0% calc(100% - 0px),
    0% 40px
  );
  clip-path: polygon(
    40px 0%,
    calc(100% - 0px) 0%,
    100% 0px,
    /*right end*/ 100% calc(100% - 40px),
    calc(100% - 40px) 100%,
    10px 100%,
    0% calc(100% - 0px),
    0% 40px
  );
}
#topCategory {
  margin-bottom: 10px;
  font-weight: bold;
  color: #87878a;
  font-size: 15px;
}
.selected-storage {
  font-size: 12px;
  border: 1px solid var(--base-danger, red);
  padding: 5px 0px;
  cursor: pointer;
  width: 95px;
  text-align: center;
}
.react-multi-carousel-item {
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .case-col {
    border-left: none;
  }
}
